main{
    height: max(100vh, 50rem);
    background-image: url("assets/images/bkg.png");
    background-repeat: no-repeat;
    object-fit: cover;
    background-position: center;
}

.live {
    height: max(100vh, 55rem);
}

@media screen and (max-width: 767px){
    main{
        height: max(100vh, 62rem);;
    }
}
